import OnboardingCommon from './OnboardingCommon.js';
import HomePage from '../pages/home.f7';
import AuthPage from '../pages/auth.f7';
import AcceptSharing from '../pages/accept_sharing.f7';
import ChooseStandard from '../pages/choose_standard.f7';
import CompanySettings from '../pages/company_settings.f7';
import DataTypesPage from '../pages/data_types.f7';
import EmailCollabPage from '../pages/email_collab.f7';
import TopToolsPage from '../pages/top_tools.f7';
import SupplierTypesPage from '../pages/supplier_types.f7';
import DataRetentionPage from '../pages/data_retention.f7';
import ITProviderPage from '../pages/it_provider.f7';
import ComponentsPage from '../pages/components.f7';
import AboutPage from '../pages/about.f7';
import FormPage from '../pages/form.f7';

import DynamicRoutePage from '../pages/dynamic-route.f7';
import RequestAndLoad from '../pages/request-and-load.f7';
import NotFoundPage from '../pages/404.f7';

const deit = new OnboardingCommon();

var routes = [
  {
    path: '/',
    name: 'home',
    component: AuthPage,
  },
  {
    path: '/home/',
    name: 'home',
    component: ComponentsPage, // TODO: Change this to the home page
    options: {
      animate: false,
      transition: 'none',
    },
  },
  {
    path: '/auth/',
    component: AuthPage,
    options: {
      animate: true,
      transition: 'f7-dive',
    },
  },
  {
    path: '/accept_sharing/:sso_provider?',
    component: AcceptSharing,
    options: {
      animate: true,
      transition: 'f7-dive',
    },
  },
  {
    path: '/choose_standard/',
    component: ChooseStandard,
    options: {
      animate: true,
      transition: 'f7-dive',
    },
  },
  {
    path: '/company_settings/',
    component: CompanySettings,
    options: {
      animate: true,
      transition: 'f7-dive',
    },
  },
  {
    path: '/pa/',
    options: {
      animate: true,
      transition: 'f7-dive',
    },
    routes: [
      {
        path: '/data_types/',
        component: DataTypesPage,
        options: {
          animate: true,
          transition: 'f7-dive',
        },
      },
      {
        path: '/email_collab/',
        component: EmailCollabPage,
        options: {
          animate: true,
          transition: 'f7-dive',
        },
      },
      {
        path: '/data_retention/',
        component: DataRetentionPage,
        options: {
          animate: true,
          transition: 'f7-dive',
        },
      },
      {
        path: '/top_tools/:tool?',
        component: TopToolsPage,
        options: {
          animate: true,
          transition: 'f7-dive',
        },
      },
      {
        path: '/supplier_types/:tool?/:supplier',
        component: SupplierTypesPage,
        options: {
          animate: true,
          transition: 'f7-dive',
        },
      },
    ],
  },
  {
    path: '/e8/',
    options: {
      animate: true,
      transition: 'f7-dive',
    },
    routes: [
      {
        path: '/it_provider/:page?',
        component: ITProviderPage,
        options: {
          animate: true,
          transition: 'f7-dive',
        },
      },
    ],
  },
  {
    path: '/components/',
    component: ComponentsPage,
  },
  {
    path: '/about/',
    component: AboutPage,
  },
  {
    path: '/form/',
    component: FormPage,
  },

  {
    path: '/dynamic-route/blog/:blogId/post/:postId/',
    component: DynamicRoutePage,
  },
  {
    path: '/request-and-load/user/:userId/',
    async: function ({ router, to, resolve }) {
      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      // User ID from request
      var userId = to.params.userId;

      // Simulate Ajax Request
      setTimeout(function () {
        // We got user data from request
        var user = {
          firstName: 'Vladimir',
          lastName: 'Kharlampidi',
          about: 'Hello, i am creator of Framework7! Hope you like it!',
          links: [
            {
              title: 'Framework7 Website',
              url: 'http://framework7.io',
            },
            {
              title: 'Framework7 Forum',
              url: 'http://forum.framework7.io',
            },
          ],
        };
        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve(
          {
            component: RequestAndLoad,
          },
          {
            props: {
              user: user,
            },
          }
        );
      }, 1000);
    },
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
