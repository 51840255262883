import $ from 'dom7';
import OnboardingCommon from './OnboardingCommon';
import Framework7 from 'framework7/bundle';
import { onAuthStateChanged } from 'firebase/auth';

// Import F7 Styles
import 'framework7/css/bundle';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.scss';

// Import Routes
import routes from './routes.prod.js';
// Import Store
import store from './store.js';

// Import main app component
import App from '../app.f7';

import AuthPage from '../pages/auth.f7';
import AcceptSharing from '../pages/accept_sharing.f7';
import ChooseStandard from '../pages/choose_standard.f7';
import CompanySettings from '../pages/company_settings.f7';
import DataTypesPage from '../pages/data_types.f7';
import EmailCollabPage from '../pages/email_collab.f7';
import TopToolsPage from '../pages/top_tools.f7';
import SupplierTypesPage from '../pages/supplier_types.f7';
import DataRetentionPage from '../pages/data_retention.f7';
import ITProviderPage from '../pages/it_provider.f7';
import ComponentsPage from '../pages/components.f7';
import AboutPage from '../pages/about.f7';
import FormPage from '../pages/form.f7';

import DynamicRoutePage from '../pages/dynamic-route.f7';
import RequestAndLoad from '../pages/request-and-load.f7';
import NotFoundPage from '../pages/404.f7';

const deit = new OnboardingCommon();

const stages = {
  createAccount: ChooseStandard,
  paymentStarted: ChooseStandard,
  paymentCompleted: CompanySettings,
};
var currentStage = AuthPage;
var stageName;

window.changePage = function (path, params = {}) {
  return window.f7.views.main.router.navigate(path, params);
};

const DIOnboarding = new Framework7({
  name: 'de.iterate | Onboarding', // App name
  theme: 'ios', // Automatic theme detection
  darkMode: false, // Auto dark mode
  colors: {
    primary: '#F1C89C',
  },
  statusbar: {
    iosOverlaysWebView: true,
  },

  el: '#app', // App root element
  component: App, // App main component
  // App store
  store: store,
  // App routes
  routes: routes,
  view: {
    browserHistory: true,
  },

  // Register service worker (only on production build)
  serviceWorker:
    process.env.NODE_ENV === 'production'
      ? {
          path: '/service-worker.js',
        }
      : {},
});

window.f7 = DIOnboarding;
window.deit = new OnboardingCommon();

window.f7.dialog.preloader = function (title = 'Loading', callback = () => {}) {
  return window.f7.dialog
    .create({
      title: title,
      text: `<img src="ducks/cool_duck.svg" style="
width: 25%;
height: 25%;
margin-left: auto;
margin-right: auto;
animation: spin 1s linear infinite;
"/>`,
      onClick: callback,
    })
    .open();
};

Object.defineProperty(String.prototype, 'capitalise', {
  value: function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false,
});

Object.defineProperty(String.prototype, 'toProperCase', {
  value: function () {
    var smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|vs?\.?|via)$/i;
    return this.replace(/([^\W_]+[^\s-]*) */g, function (match, index, title) {
      if (
        index > 0 &&
        index + match.length !== title.length &&
        match.search(smallWords) > -1 &&
        title.charAt(index - 2) !== ':' &&
        (title.charAt(index + match.length) !== '-' || title.charAt(index - 1) === '-') &&
        (title.charAt(index + match.length) !== "'" || title.charAt(index - 1) === "'") &&
        title.charAt(index - 1).search(/[^\s-]/) < 0
      ) {
        return match.toLowerCase();
      }
      return match.charAt(0).toUpperCase() + match.substr(1).toLowerCase();
    });
  },
  enumerable: false,
});

Object.defineProperty(String.prototype, 'cycle', {
  value: function (arr) {
    const i = arr.indexOf(this.toString());
    if (i === -1) return undefined;
    return arr[(i + 1) % arr.length];
  },
  enumerable: false,
});

window.cycle = function (arr, val) {
  val.cycle(arr);
};

((win) => {
  const nativeLocalStorage = win.localStorage;
  win.nativeLocalStorage = nativeLocalStorage; // keep the original usage

  class DeitStorage {
    setItem(key, value) {
      console.log('DeitStorage.setItem', key, value);
      if (key.includes('onboarding-') || key.includes('firebase')) {
        nativeLocalStorage.setItem(key, value);

        return nativeLocalStorage.getItem(key);
      }

      let newKey = `onboarding-${key}`;
      nativeLocalStorage.setItem(newKey, value);

      return nativeLocalStorage.getItem(newKey);
    }

    getItem(key) {
      console.log('DeitStorage.getItem', key);

      if (key.includes('onboarding-') || key.includes('firebase')) {
        return nativeLocalStorage.getItem(key);
      }

      let newKey = `onboarding-${key}`;

      return nativeLocalStorage.getItem(newKey);
    }

    removeItem(key) {
      console.log('DeitStorage.removeItem', key);

      if (key.includes('onboarding-') || key.includes('firebase')) {
        return nativeLocalStorage.removeItem(key);
      }

      let newKey = `onboarding-${key}`;

      return nativeLocalStorage.removeItem(newKey);
    }

    clear() {
      console.log('DeitStorage.clear');

      return nativeLocalStorage.clear();
    }
  }

  const deitStorage = new DeitStorage();
  // Assign the newly created instance to localStorage
  Object.defineProperty(win, 'localStorage', {
    value: deitStorage,
    writable: true,
  });
})(window);
